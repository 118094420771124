.Language {
    position: fixed;
    display: inline-block;
    top: 3rem;
    right: 3rem;

    .language-btn {
        display: flex;
        align-items: center;
        background: #00000015;
        border: 1px solid #fdf1b8;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        cursor: pointer;
        color: #fdf1b8;

        .language-icon {
            width: 20px;
            height: 20px;
            margin-right: 0.5rem;
        }

        span {
            margin-right: 0.5rem;

            &.arrow {
                font-size: 0.8rem;
            }
        }
    }

    .language-menu {
        position: absolute;
        top: 100%;
        right: 0;
        background: #00000015;
        border: 1px solid #fdf1b8;
        border-radius: 5px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-top: 0.5rem;
        z-index: 1000;

        .language-option {
            display: flex;
            align-items: center;
            padding: 0.5rem 1rem;
            cursor: pointer;

            &:hover {
                background: #f0f0f0;
            }

            .language-icon {
                width: 20px;
                height: 20px;
                margin-right: 0.5rem;
            }
        }
    }
}

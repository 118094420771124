.Home {
    .slide-up {
        animation: slideUp 0.7s ease-out;
      }

      .column {
        display: flex;
        flex-direction: column;
      }
      .gap-3 {
        gap: 3rem;
      }

      @keyframes slideUp {
        0% {
          transform: translateY(20%);
        }
        100% {
          transform: translateY(0);
        }
        
      }

    .top__home--background {
        height: 100vh; 
        background-size: cover; 
        background-attachment: fixed; 
        overflow: hidden;

        .top__home {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }

        .intro {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 70%; 
            margin: 0 auto; 
            color: #fdf1b9; 

            h3 {
                span {
                    color: #FFF300; 
                }
            }

            h1 {
                font-size: 10rem; 
            }
        }

        

        .top__information {
            width: 70%; 
            margin: 0 auto; 
            display: flex; 
            justify-content: space-between; 

            div {
                display: flex; 
                flex-direction: column; 
                justify-content: center; 
                align-items: center; 
                gap: 5rem; 
                color: #fdf1b9; 
                font-size: 2.3rem; 
            }
        }

        .color__svg--front,
        .color__svg--back,
        .color__svg--mobile,
        .color__svg--anglais {
            fill: #fdf1b9; 
        }

        .color__svg--front:hover {
            fill: #FF5733; 
        }

        .color__svg--back:hover {
            fill: #0000FF; 
        }

        .color__svg--mobile:hover {
            fill: #048B9A; 
        }

        .color__svg--anglais:hover {
            fill: #83f52c; 
        }

        .svg__size {
            height: 7rem; 
            width: 7rem; 
        }
    }


    .infos {
        width: 80%; 
        margin: 0 auto; 
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr; 

        .photo {
            gap: 1rem; 
            padding: 50px 0 0 50px; 
            display: flex; 
            flex-direction: column; 
        }

        .description {
            grid-column: span 2; 
        }
    }
    

}





@media screen and (min-width: 300px) {

    .Home {

        .top__home--background {
            background-image: url("../../../public/images/milkyway-mobile.jpg"); 
            gap: 2rem; 

            .intro {

                h2 {
                    font-size: 12px; 
                }

                h1 {
                    font-size: 30px; 
                    margin-top: 5px; 
                }

                h3 {
                    font-size: 9px; 
                    margin-top: 6px; 
                }
            }

            .top__information {
                div {
                    gap: 1rem; 

                    label {
                        font-size: 10px; 
                    }

                    .svg__size {
                        width: 30px; 
                        height: 30px; 
                    }
                }
            }
        }


        .infos {

            .photo {
                grid-column: span 3; 
                gap: 10px; 
                flex-direction: row; 
                justify-content: space-around; 
                
                padding: 15px 0 0 15px; 

                .picture {
                    .profil {
                        height: 60px; 
                        width: 60px; 
                        border-radius: 50%; 
                    }
                }

                h1 {
                    display: flex; 
                    align-items: center; 
                    font-size: 10px; 
                }

                h5 {
                    font-size: 10px; 
                    display: flex; 
                    align-items: center; 
                }

            }

            .description {
                padding: 10px 0 0 10px; 
                grid-column: span 3; 

                h1 {
                    display: flex; 
                    justify-content: center; 
                    font-size: 15px; 
                }

                h2 {
                    font-size: 10px; 
                }
            }

        }
    }
}




@media screen and (min-width: 660px) {
    .Home {
        .top__home--background {
            background-image: url("../../../public/images/space.jpg"); 
            background-position: center; 
            gap: 8rem; 

            .intro {
                h2 {
                    font-size: 1.8rem; 
                }

                h3 {
                    font-size: 2.2rem; 
                    margin-top: 6px; 
                }

                h1 {
                    font-size: 6rem; 
                }
            }

            .top__information {

                div {
                    gap: 3rem; 

                    label {
                        font-size: 2.2rem; 
                    }

                    .svg__size {
                        height: 5rem; 
                        width: 5rem; 
                    }
                }
            }
        }

        .infos {
            padding: 30px 30px 0 30px; 
            width: 100%;

            .photo {
                flex-direction: column; 
                grid-column: span 1; 

                .picture {
                    .profil {
                        height: 150px; 
                        width: 150px; 
                    }
                }

                h1 {
                    font-size: 20px; 
                }

                h5 {
                    font-size: 16px; 
                }
            }

            .description {
                grid-column: span 2; 

                h1 {
                    font-size: 20px; 
                }

                h2 {
                    font-size: 16px; 
                }
            }

        }
    }
}



@media screen and (min-width: 1290px) {
    .Home {
        .top__home--background {
            gap: 10 rem; 

            .intro {
                h2 {
                    font-size: 2.8rem; 
                }

                h3 {
                    font-size: 2.5rem; 
                    margin-top: 6px; 
                }

                h1 {
                    font-size: 10rem;
                    
                    
                }
            }

            .top__information {

                div {
                    gap: 5rem; 

                    label {
                        font-size: 2.7rem; 
                    }

                    .svg__size {
                        height: 7rem; 
                        width: 7rem; 
                    }
                }
            }
        }

    }
}
.Portfolio {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 90%;
    gap: 2rem;
    padding: 2rem;
    z-index: 9999;

    .list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }
}
.Skills {
    width: 90%;
    height: 400px; /* Ajoutez une hauteur explicite au conteneur */
    min-height: 20rem;
    padding: 2rem;
    position: relative; /* Nécessaire pour le positionnement absolu des enfants */

    .skills__front,
    .skills__back {
        width: 40%; /* Ajustez la taille des cartes si nécessaire */
        background-image: url('../../../public/images/stars.jpg');
        background-size: cover;
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        flex-direction: column;
        padding: 2rem;
        gap: 2rem;
        border-radius: 1.5rem;
        position: absolute; /* Permet un positionnement indépendant */
    }

    .skills__front {
        top: 0; /* Positionne la carte en haut */
        left: 2rem; /* Positionne la carte à gauche */
    }

    .skills__back {
        bottom: 0; /* Positionne la carte en bas */
        right: 0; /* Positionne la carte à droite */
    }

    .progress-bar {
        width: 100%;
        background-color: #e0e0e0;
        border-radius: 8px;
        overflow: hidden;
        margin-top: 0.5rem;
        height: 1rem;
    }

    .progress {
        height: 100%;
        border-radius: 8px;
        transition: width 0.3s ease;
    }
}
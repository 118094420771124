.Header {
    position: fixed;
    /* Maintient l'élément visible en défilement */
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
    /* Fond clair avec 3% d'opacité */
    display: flex;
    flex-direction: column;
    right: 0;
    /* Collé à droite */
    top: 50%;
    /* Centrage vertical */
    transform: translateY(-50%);
    /* Ajuste pour un vrai centrage vertical */
    border-radius: 5rem;
    padding: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Ajoute une ombre pour un effet visuel */
    /* Adapte la largeur au contenu */
    max-height: 90%;
    /* Prévention du débordement */
    overflow-y: auto;
    /* Barre de défilement si nécessaire */
    z-index: 10000;


    .links__header {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .hover:hover {
        text-decoration: underline; // lors du survol souligne les textes
        color: #FFFF00; // lors du survol change la couleur de la police de #fdf1b8 à #FFFF00
        fill: #FFFF00; // lors du survol change la couleur de l'svg de #fdf1b8 à #FFFF00 
    }
}


@media screen and (min-width: 300px) {
    .Header {
        display: flex; // met en ligne le contenu et le rend malléable
        justify-content: space-between; // laisse un écart equivalent entre chaque éléments et se collent aux bords

        div {
            width: 10%; // les div prennent 10% de largeur comparé au parent (navigation)
            padding: 20px 0 20px 0; // créer un espacement de 20px en haut et en bas des link dans leur div(haut/droit/bas/gauche)
        }


    }

    .hover {
        font-size: 15px; // taille de la police 15px
    }
}

@media screen and (min-width: 660px) {
    .Header {
        justify-content: flex-end; // positionne les enfants à la fin de l'axe x
        gap: 6rem; // sépare de 20 px les enfants

        div {
            width: 10%; // les div prennent 10% de largeur comparé au parent (navigation)
            padding: 20px 0 20px 0; // créer un espacement de 20px en haut et en bas des link dans leur div(haut/droit/bas/gauche)
        }
    }

    .hover {
        font-size: 15px; // taille de la police 15px
    }

}

@media screen and (min-width: 1000px) {
    .Header {
        justify-content: flex-end; // positionne les enfants à la fin de l'axe x
        gap: 3rem; // sépare de 20 px les enfants
        margin-right: 2rem;


        .house {
            fill: #fdf1b8;
            height: 3rem;
            width: 3rem;
        }

        .house:hover {
            fill: #FFFF00;
        }
    }
}
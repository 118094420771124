.Footer {
    // position: fixed; // fixe le bas de la fenêtre
    // bottom: 0; // se positionne tout en bas pour que le footer 
    // right: 0; // à droite
    // left: 0; // puis à gauche
    width: 100%; // prend 100% de largeur de la page
    background: rgba(0, 0, 0, 0.1); // couleur de fond du footer en #000000 avec 10 % d'opacité
    display: flex; // met en ligne les enfants
    justify-content: center; // centre dans l'axe x
    align-items: center; // centre dans l'ax y
    flex-direction: column; // met en colonne les enfants

    .footer__links {
        display: flex; // met en ligne les div

        .linkedin__link {
            background-position: center; // centre le gif
            background-repeat: no-repeat; // enlève la répétition du gif
        }

        .github__link {
            background-position: center; // centre le gif 
            background-repeat: no-repeat; // enlève la répétition du gif
        }
    }
}


@media screen and (min-width: 300px) {
    .Footer {

        .footer__links {
            height: 30px; // hauteur de la div des gifs à 30px

            div {
                width: 30px; // taille des balises div des gifs 
                height: 30px; // de 30px de large et 30px de hauteur
            }

            .linkedin__link {
                background-size: 30px 30px; // dimentionne le gif de 30px de hauteur et largeur car trop petit comparé au github
            }

            .github__link {
                background-size: 18px 18px; // dimentionne de 18px hauteur et largeur le gif pour mieux harmoniser avec l'autre gif
            }
        }

        .foot p {
            font-size: 10px; // taille de la police à 10px
        }
    }
}

@media screen and (min-width: 600px) {
    .Footer {
        gap: 1rem; // sépare les enfants de 10 px

        .footer__links {

            div {
                height: 40px; // taille des balises div des gifs
                width: 40px; // de 40px de large et 40px de hauteur
            }

            .linkedin__link {
                background-size: 45px 45px; // dimentionne le gif 45px hauteur largeur car trop petit comparé au github
            }

            .github__link {
                background-size: 30px 30px; // dimentionne le gif 30px hauteur largeur car trop petit comparé au github
            }
        }

        .foot p {
            font-size: 15px; // taille de la police à 10px
        }
    }
}

@media screen and (min-width: 1000px) {
    .Footer {
        gap: 2rem; // sépare les enfants de 20px

        .footer__links {

            div {
                height: 50px; // taille des balises div des gifs
                width: 50px; // de 50px de large et 50px de hauteur
            }

            .linkedin__link {
                background-size: 60px 60px; // dimentionne le gif à 60px hauteur et largeur car trop petit comparé au github
            }

            .github__link {
                background-size: 40px 40px; // dimentionne le gif  40px hauteur et largeur car trop petit comparé au github
            }
        }

        .foot p {
            font-size: 15px; // taille de la police à 10px
        }
    }
}
.Card {
    .card {
        position: relative; // set en tant que parent car l'enfant div est en postion absolute

        img {
            width: 100%;
            height: 100%;
            // dans ce cas l'img défini la box
            display: block; // se met en block
            border-radius: 10px; // arrondi les bords de la photo de 10px
            filter: brightness(90%); // assombri la photo pour éviter que ça soit 
                                     // trop claire pour l'écriture affiché en premier plan
        }

        &>div {
            position: absolute; // rend en absolute pour être maléable dans .card (absolute)
            top: 0; // 
            bottom: 0; //
            left: 0; // prend tout .card 
            right: 0; //
            box-shadow: 0 0 20px #eee; // créer une ombre clair autour de la list de projet
            border-radius: 10px; // harmonise avec l'img
            // créer un petit gradient de couleur clair en haut avec une opacité de 10% 
            // et noir en bas avec une opacité de 10%
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
            display: flex; // rend le contenu maléable et se met en ligne
            padding: 20px; // espace l'enfant du parent de 20px
            flex-direction: column; // rend le contenu en colonne axe x et y s'échangent de position
            justify-content: flex-end; // déplace le contenu à la fin de la colonne axe x
            align-items: center; // centre le contenu axe y

            &:hover {
                // lors du survol change l'opacité de la div en noir avec un gradient de 
                // couleur clair en haut avec une opacité de 10% 
                // et noir en bas avec une opacité de 60%
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.6) 100%);
            }

            h2,
            h5,
            h7 {
                color: #fdf1b9; // écrit de couleur noir
                font-family: Montserrat; // police d'écriture Montserrat
                font-weight: bold; // gras pour la police d'écriture
                margin-bottom: 0.5rem; // marge de 5px en bas
            }

            h2 {
                font-size: 18px; // taille de la police à 18px
            }

            h5 {
                font-size: 12px; // taille de la police à 12px
            }

            h7 {
                font-size: 11px; // taille de la police à 11px
            }
        }
    }
}